import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { router } from './Routes';
import './App.css';
import { NameProvider } from './Context/NameContext';
import { storesArr } from './Utils/Constants';
import DisabledPage from './Pages/DisabledPage';
import { FiltersProvider } from './Context/FiltersContext';
import Loader from './Components/Loader';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const url = new URL(window.location.href);
  const hostnameParts = url.hostname.split('.');
  const store = hostnameParts.length > 2 ? hostnameParts.slice(0, hostnameParts.length - 2).join('.') : '';
  const storeObject = storesArr.find((item) => item.name === store);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
      />
      {isLoading ?
        <Loader /> :
        <>
          {storeObject?.status === "disabled"
            ? <DisabledPage />
            : <NameProvider>
              <FiltersProvider>
                <RouterProvider
                  router={router}
                  fallbackElement={<Spin fullscreen={false} size="small" tip="Researching..." />}
                />
              </FiltersProvider>
            </NameProvider>}
        </>}
    </>
  );
}

export default App;
