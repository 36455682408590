import "./productDetailSkeleton.scss"

export const ProductBannerSkeleton = () => {
    return (
        <div className="container">
            <div className="skeleton-product-banner">
                <div className="skeleton-row">
                    <div className="skeleton-image-section">
                        <div className="skeleton-thumbnail"></div>
                        <div className="skeleton-thumbnail"></div>
                        <div className="skeleton-thumbnail"></div>
                    </div>
                    <div>
                        <div className="skeleton-main-image"></div>
                    </div>
                    <div className="skeleton-details-section">
                        <div className="skeleton-title-wrap">
                            <div className="skeleton-title">
                                <p></p>
                                <p></p>
                            </div>
                            <div className="skeleton-progress"></div>
                        </div>
                        <div className="d-none d-md-block skeleton-filter-list"></div>
                        <div className="d-none d-md-flex skeleton-values">
                            <div className="skeleton-value"></div>
                            <div className="skeleton-value"></div>
                            <div className="skeleton-value"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column d-md-none gap-2 mb-3">
                <div className="skeleton-filter-list"></div>
                <div className="skeleton-values">
                    <div className="skeleton-value"></div>
                    <div className="skeleton-value"></div>
                    <div className="skeleton-value"></div>
                </div>
            </div>
        </div>
    );
};