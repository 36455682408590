import React, { useState, useEffect, useRef } from "react";
import ReactImageMagnify from "@blacklab/react-image-magnify";
import ReactStars from "react-rating-stars-component";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { ReactComponent as ZoomIndicatorSvg } from "../../Assets/Images/Svgs/zoomIndicator.svg";
import { Progress } from "antd";
import { getRadialColor } from "../../Utils/CommonFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import { brandClaimStatus } from "../../APIs/functions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import fallBackImg from "../../Assets/Images/leaf.png";
import "react-lazy-load-image-component/src/effects/blur.css";
import 'swiper/css';
import "./index.scss";
import { handleProductType, handleRadialClick, handleSlideTrack } from "../../Gtag";
import PDPFilterList from "../PDPFilter";
import { ProductBannerSkeleton } from "../Skeletons/productDetailPage";

const ProductBanner = ({ product, scrollref }) => {
  const {
    productName,
    productBrand,
    values,
    quantityArr,
    totalGs,
    productImage,
    allImages,
    rating,
    numberofReviews,
  } = product;

  const queries = {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    retry: false,
    cacheTime: 325000,
    staleTime: 300000,
  };
  const [selectedImg, setSelectedImg] = useState({ src: "", state: false });
  const [isFullScreen, setIsFullscreen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [gsFeel, setGsFeel] = useState("");

  const pdpImgRef = useRef(null);
  const pdpWrapRef = useRef(null);

  const changeImage = (src) => {
    setSelectedImg({ src: src });
  };

  const {
    data: brandStatus,
    isLoading: brandStatusLoading,
    error: brandStatusError,
  } = useQuery(
    ["brandStatus", productBrand],
    () => brandClaimStatus(productBrand && productBrand.split("-").join(" ")),
    queries
  );

  const toggleFullScreen = () => {
    if (isMobile) {
      setIsFullscreen(!isFullScreen);
      let elem = pdpImgRef.current;
      elem.classList.contains("w-full-screen")
        ? elem.classList.remove("w-full-screen")
        : elem.classList.add("w-full-screen");
    } else return;
  };

  useEffect(() => {
    //send product type to GA
    handleProductType(product?.productType)
    var x = window.matchMedia("(max-width: 767px)");
    if (x.matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    let score = Math.round(Number(totalGs));
    if (score >= 90) {
      setGsFeel("Great 😍");
    } else if (score >= 75 && score <= 89) {
      setGsFeel("Good 😊");
    } else if (score >= 50 && score <= 74) {
      setGsFeel("Okay 🙂");
    } else if (score >= 26 && score <= 49) {
      setGsFeel("Limit 😐");
    } else if (score <= 24) {
      setGsFeel("Avoid 😶");
    } else {
      setGsFeel("");
    }
  }, []);

  useEffect(() => {
    let header = document.querySelector(".header");
    let btn = document.querySelector(".pdp-fullwidth-close-btn");
    if (!header || !btn) return;
    if (isFullScreen) {
      header.style.display = "none";
      btn.style.display = "block";
      pdpWrapRef.current.style.overflow = isMobile ? "visible" : null;
    } else {
      header.style.display = "block";
      btn.style.display = "none";
      pdpWrapRef.current.style.overflow = isMobile ? "hidden" : null;
    }
  }, [isFullScreen]);

  return (
    <div ref={pdpWrapRef} id="productBanner">
      <div onClick={toggleFullScreen} className="pdp-fullwidth-close-btn">
        <button>
          <FontAwesomeIcon icon={faTimes} color={"gray"} />
        </button>
      </div>
      <div className="row">
        {productImage && productImage !== "" && (
          <div className="pdpWrap col-md-6 col-4 px-1">
            <ul className="pdpImgThumbnails">
              <li onClick={() => changeImage(productImage)}>
                {productImage ? (
                  <img
                    className="img-fluid"
                    src={productImage}
                    alt="product"
                    loading="lazy"
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={fallBackImg}
                    alt="product"
                    loading="lazy"
                  />
                )}
              </li>
              {allImages.length > 0 &&
                allImages
                  .split(",")
                  .slice(0, 8)
                  .map((item) => {
                    return (
                      <li onClick={() => changeImage(item)} key={item}>
                        {item ? (
                          <LazyLoadImage
                            className="img-fluid"
                            src={item}
                            alt={item}
                            effect="blur"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src={fallBackImg}
                            alt="placeholder"
                          />
                        )}
                      </li>
                    );
                  })}
            </ul>
            <div
              ref={pdpImgRef}
              onClick={toggleFullScreen}
              className="pdpMainImg"
            >
              {isMobile ? (
                <>
                  {isFullScreen ? (
                    <TransformWrapper
                      centerOnInit={true}
                      initialPositionX={50}
                      initialPositionY={50}
                    >
                      <TransformComponent>
                        {selectedImg ? (
                          <img
                            src={
                              selectedImg?.src ? selectedImg?.src : productImage
                            }
                            alt="product"
                            loading="lazy"
                          />
                        ) : (
                          <img alt="product" src={fallBackImg} />
                        )}
                      </TransformComponent>
                    </TransformWrapper>
                  ) : (
                    <Swiper
                      spaceBetween={50}
                      slidesPerView={1}
                      pagination={true}
                      className="pdpMobileSlider"
                      modules={[Pagination]}
                      onSlideChange={(swiper) => {
                        handleSlideTrack(productName);
                        setSelectedImg({
                          src: swiper.slides[swiper.realIndex].childNodes[0]
                            .currentSrc,
                        });
                      }}
                    >
                      <SwiperSlide>
                        {" "}
                        {productImage ? (
                          <img src={productImage} alt="product" />
                        ) : (
                          <img alt="product" src={fallBackImg} />
                        )}{" "}
                      </SwiperSlide>
                      {allImages &&
                        allImages.split(",").map((item) => {
                          return (
                            <SwiperSlide key={item}>
                              {item ? (
                                <img loading="lazy" src={item} alt="product" />
                              ) : (
                                <img alt="product" src={fallBackImg} />
                              )}
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  )}
                </>
              ) : (
                <ReactImageMagnify
                  magnifyContainerProps={{
                    width: "150%",
                    height: "110%",
                  }}
                  imageProps={{
                    alt: `${productName ? productName : "product image"}`,
                    isFluidWidth: true,
                    src: selectedImg?.src ? selectedImg?.src : productImage,
                  }}
                  magnifiedImageProps={{
                    src: selectedImg?.src ? selectedImg?.src : productImage,
                    width: 1200,
                    height: 900,
                  }}
                />
              )}
              <div
                className="d-none d-md-flex align-items-center justify-content-center"
                id="pdpZoomIndicator"
              >
                <ZoomIndicatorSvg />
                <span>Hover to zoom</span>
              </div>
            </div>
          </div>
        )}
        <div className={!productImage || productImage === "" ? "col-12 col-md-12 px-2" : "col-8 col-md-6 px-1"}>
          {/* {!brandStatusLoading &&
              !brandStatusError &&
              brandStatus !== null &&
              productBrand && (
                <div className="pdpBrand d-flex align-items-center mb-2">
                  <p className="mb-0 me-3"> {productBrand} </p>
                  {brandStatus?.length <= 0 ||
                    brandStatus[0]?.is_active === false ? (
                    <span className="brandUnclaimed">
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 14 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.4392 3.14008C9.88583 2.84793 8.06832 1.60213 7.43371 1.06033C7.27838 0.927807 7.05498 0.927807 6.89989 1.06033C6.26544 1.60199 4.4479 2.84796 0.894405 3.13997C0.653409 3.15972 0.475441 3.38281 0.502772 3.63397L1.26001 10.5669C1.48889 12.6626 2.5177 13.8366 4.12656 15.1083C5.10904 15.8847 6.22221 16.6396 7.16667 16.9609C8.11099 16.6395 9.22392 15.8851 10.2062 15.1089C11.8152 13.8376 12.8442 12.6633 13.0731 10.5675L13.8306 3.63383C13.8579 3.38295 13.6798 3.15971 13.4389 3.14011L13.4392 3.14008ZM10.0572 7.55633L6.65078 11.1201C6.55521 11.2202 6.42528 11.2764 6.29001 11.2764C6.15474 11.2764 6.02493 11.2202 5.92924 11.1201L4.27665 9.39131C4.07732 9.18293 4.07732 8.84502 4.27665 8.63661C4.47585 8.42809 4.79887 8.42809 4.99809 8.63661L6.29004 9.98813L9.33588 6.80146C9.53508 6.59294 9.8581 6.59294 10.0573 6.80146C10.2567 7.00998 10.2567 7.34776 10.0573 7.55626L10.0572 7.55633Z"
                          fill="#929292"
                        />
                      </svg>
                      <span>
                        {" "}
                        <span
                          className="text-decoration-underline"
                          onClick={() =>
                            window.open(
                              "https://greenchoice797944.typeform.com/claim-brand",
                              "_blank"
                            )
                          }
                        >
                          Unclaimed
                        </span>{" "}
                      </span>
                    </span>
                  ) : (
                    <span className="brandClaimed">
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 13 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.3218 1.90675C8.2126 1.65112 6.62228 0.561041 6.067 0.0869679C5.93109 -0.0289893 5.7356 -0.0289893 5.5999 0.0869679C5.04476 0.560919 3.45441 1.65115 0.345105 1.90665C0.134233 1.92394 -0.0214888 2.11914 0.00242582 2.3389L0.665006 8.40524C0.865282 10.239 1.76549 11.2662 3.17324 12.379C4.03291 13.0583 5.00694 13.7189 5.83333 14C6.65961 13.7187 7.63343 13.0587 8.49296 12.3795C9.9008 11.2671 10.8012 10.2396 11.0014 8.40573L11.6642 2.33878C11.6882 2.11926 11.5323 1.92392 11.3216 1.90678L11.3218 1.90675ZM8.36251 5.77097L5.38193 8.88926C5.29831 8.97685 5.18462 9.02607 5.06626 9.02607C4.9479 9.02607 4.83431 8.97685 4.75058 8.88926L3.30457 7.37658C3.13015 7.19424 3.13015 6.89857 3.30457 6.71622C3.47887 6.53376 3.76151 6.53376 3.93583 6.71622L5.06629 7.89879L7.7314 5.11046C7.9057 4.928 8.18834 4.928 8.36265 5.11046C8.53707 5.29291 8.53707 5.58847 8.36265 5.77091L8.36251 5.77097Z"
                          fill="#5199B8"
                        />
                      </svg>
                      <span> Claimed </span>
                    </span>
                  )}
                </div>
              )} */}
          <div className="d-flex flex-md-row flex-column gap-2 justify-content-between align-items-start mt-3">
            <h1 className={`text-capitalize pe-2 mb-0`}>{productName}</h1>
            <div className="gsScore text-center d-none d-sm-block" onClick={scrollref}>
              <Progress
                onClick={() => handleRadialClick(productName)}
                type="circle"
                percent={Math.round(totalGs)}
                size={63}
                className="fw-bold text-primary pt-1"
                strokeWidth="11"
                strokeLinecap="round"
                format={(percent) => percent + ""}
                strokeColor={`${getRadialColor(Math.round(totalGs))}`}
              />
              <span className="d-block">{gsFeel}</span>
            </div>
            <div className="col-5 gsScore-wrap px-0 d-flex d-sm-none">
              <div className="gsScore text-center" onClick={scrollref}>
                <Progress
                  onClick={() => handleRadialClick(productName)}
                  type="circle"
                  percent={Math.round(totalGs)}
                  size={63}
                  className="fw-bold text-primary pt-1"
                  strokeWidth="11"
                  strokeLinecap="round"
                  format={(percent) => percent + ""}
                  strokeColor={`${getRadialColor(Math.round(totalGs))}`}
                />
                <span className="d-block">{gsFeel}</span>
              </div>
            </div>
          </div>
          <div className="values sizes px-1 mt-4 d-none d-lg-block">
            <div className="col-12">
              <PDPFilterList product={product} />
            </div>
            <h6 className="mb-3">Meets standards for:</h6>
            <div className="values-wrap">
              {values.length > 0 &&
                values.map((item, index) => {
                  return <button key={item + index}>{item}</button>;
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 d-block d-md-none py-2">
        <PDPFilterList product={product} />
      </div>
      {values.length > 0 && <div className="col-12 values mt-1 sizes d-block d-md-none">
        <h6>Meets standards for:</h6>
        <div className="values-wrap">
          {values.map((item, index) => {
            return <button key={item + index}>{item}</button>;
          })}
        </div>
      </div>}
    </div>
  );
};

export default ProductBanner;
